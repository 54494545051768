import * as React from "react";

import SEO from "../components/seo";
import ExitButton from "../components/exit-button";
import * as styles from "./press.module.scss";

// markup
const PressPage = () => {
  return (
    <>
      <SEO title="Press" />
      <ExitButton />
      <div>
        <h1 className={styles.title}>Latest Press Releases</h1>
        <div className={styles.item}>
          <a href="https://therealdeal.com/sponsored/surya-capital-partners/adi-chughs-maverick-commercial-properties-rebrands-as-surya-capital-partners-ahead-of-10-year-anniversary" target="_blank" rel="noopener noreferrer">
            <h4>
              <span>The Real Deal</span>
              <svg className={styles.arrow} x="0" y="0" viewBox="0 0 17.3 30">
                <path fill="#1d1d1b" d="M2.3 0L0 2.4l11 10.9 1.6 1.7-1.6 1.7L0 27.6 2.3 30l15-15z"/>
              </svg>
            </h4>
            <p>Adi Chugh’s Maverick Commercial Properties Rebrands as Surya Capital Partners Ahead of 10 Year Anniversary</p>
            <hr />
          </a>
        </div>
        <div className={styles.item}>
          <a href="https://labusinessjournal.com/news/2021/apr/26/san-pedro-development-100-million-loan/" target="_blank" rel="noopener noreferrer">
            <h4>
              <span>LA Business Journal</span>
              <svg className={styles.arrow} x="0" y="0" viewBox="0 0 17.3 30">
                <path fill="#1d1d1b" d="M2.3 0L0 2.4l11 10.9 1.6 1.7-1.6 1.7L0 27.6 2.3 30l15-15z"/>
              </svg>
            </h4>
            <p>San Pedro Development Lands $110 Million Loan</p>
            <hr />
          </a>
        </div>
        <div className={styles.item}>
          <a href="https://rew-online.com/mag-partners-lands-173m-construction-loan/" target="_blank" rel="noopener noreferrer">
            <h4>
              <span>Real Estate Weekly</span>
              <svg className={styles.arrow} x="0" y="0" viewBox="0 0 17.3 30">
                <path fill="#1d1d1b" d="M2.3 0L0 2.4l11 10.9 1.6 1.7-1.6 1.7L0 27.6 2.3 30l15-15z"/>
              </svg>
            </h4>
            <p>MAG Partners lands $173M construction loan</p>
            <hr />
          </a>
        </div>
        <div className={styles.item}>
          <a href="https://therealdeal.com/national/2020/12/15/alex-rodriguez-and-adi-chugh-join-650m-hotel-fund/" target="_blank" rel="noopener noreferrer">
            <h4>
              <span>The Real Deal</span>
              <svg className={styles.arrow} x="0" y="0" viewBox="0 0 17.3 30">
                <path fill="#1d1d1b" d="M2.3 0L0 2.4l11 10.9 1.6 1.7-1.6 1.7L0 27.6 2.3 30l15-15z"/>
              </svg>
            </h4>
            <p>Alex Rodriguez and Adi Chugh join $650M hotel fund</p>
            <hr />
          </a>
        </div>
        <div className={styles.item}>
          <a href="https://www.wsj.com/articles/a-rod-swings-for-the-fences-with-new-hotel-real-estate-fund-11608037201" target="_blank" rel="noopener noreferrer">
            <h4>
              <span>The Wall Street Journal</span>
              <svg className={styles.arrow} x="0" y="0" viewBox="0 0 17.3 30">
                <path fill="#1d1d1b" d="M2.3 0L0 2.4l11 10.9 1.6 1.7-1.6 1.7L0 27.6 2.3 30l15-15z"/>
              </svg>
            </h4>
            <p>A-Rod Swings for the Fences With New Hotel Real-Estate Fund</p>
            <hr />
          </a>
        </div>
        <div className={styles.item}>
          <a href="https://www.dailymail.co.uk/news/article-9056175/Alex-Rodriguez-takes-ambitious-swing-hospitality-industry-new-650M-hotel-venture.html" target="_blank" rel="noopener noreferrer">
            <h4>
              <span>The Daily Mail</span>
              <svg className={styles.arrow} x="0" y="0" viewBox="0 0 17.3 30">
                <path fill="#1d1d1b" d="M2.3 0L0 2.4l11 10.9 1.6 1.7-1.6 1.7L0 27.6 2.3 30l15-15z"/>
              </svg>
            </h4>
            <p>Alex Rodriguez takes the ultimate pandemic gamble with $650M venture to acquire 20 hotels across North America and the Caribbean</p>
            <hr />
          </a>
        </div>
        <div className={styles.item}>
          <a href="https://brokerpulse.com/2019/03/18/on-the-call-with-adi-chug-commercial-real-estates-newest-matchmaker" target="_blank" rel="noopener noreferrer">
            <h4>
              <span>Broker Pulse</span>
              <svg className={styles.arrow} x="0" y="0" viewBox="0 0 17.3 30">
                <path fill="#1d1d1b" d="M2.3 0L0 2.4l11 10.9 1.6 1.7-1.6 1.7L0 27.6 2.3 30l15-15z"/>
              </svg>
            </h4>
            <p>On the call with Adi Chugh: Commercial real estate’s newest matchmaker</p>
            <hr />
          </a>
        </div>
        <div className={styles.item}>
          <a href="https://www.prnewswire.com/news-releases/real-estate-financing-luminary-adi-chugh-ceo-of-remissary-to-present-on-land-acquisition-pre-construction-and-construction-loans-panel-on-november-13th-300745422.html" target="_blank" rel="noopener noreferrer">
            <h4>
              <span>Cision PR Newswire</span>
              <svg className={styles.arrow} x="0" y="0" viewBox="0 0 17.3 30">
                <path fill="#1d1d1b" d="M2.3 0L0 2.4l11 10.9 1.6 1.7-1.6 1.7L0 27.6 2.3 30l15-15z"/>
              </svg>
            </h4>
            <p>Real Estate Financing Luminary Adi Chugh, CEO Of Remissary, To Present On Land Acquisition, Pre-Construction And Construction Loans Panel On November 13th</p>
            <hr />
          </a>
        </div>
        <div className={styles.item}>
          <a href="https://therealdeal.com/2016/10/07/watch-what-are-the-new-realities-of-financing-condo-projects-in-nyc/" target="_blank" rel="noopener noreferrer">
            <h4>
              <span>The Real Deal</span>
              <svg className={styles.arrow} x="0" y="0" viewBox="0 0 17.3 30">
                <path fill="#1d1d1b" d="M2.3 0L0 2.4l11 10.9 1.6 1.7-1.6 1.7L0 27.6 2.3 30l15-15z"/>
              </svg>
            </h4>
            <p>What are the new realities of financing condo projects?</p>
            <hr />
          </a>
        </div>
        <div className={styles.item}>
          <a href="https://www.globest.com/sites/globest/2015/08/10/chugh-deals-are-like-a-1000-piece-puzzle/" target="_blank" rel="noopener noreferrer">
            <h4>
              <span>Globe St.</span>
              <svg className={styles.arrow} x="0" y="0" viewBox="0 0 17.3 30">
                <path fill="#1d1d1b" d="M2.3 0L0 2.4l11 10.9 1.6 1.7-1.6 1.7L0 27.6 2.3 30l15-15z"/>
              </svg>
            </h4>
            <p>Chugh: Deals are Like a 1,000-Piece Puzzle</p>
            <hr />
          </a>
        </div>
        <div className={styles.item}>
          <a href="https://rew-online.com/finance-upstart-making-himself-heard-among-industry-bigs/" target="_blank" rel="noopener noreferrer">
            <h4>
              <span>Real Estate Weekly</span>
              <svg className={styles.arrow} x="0" y="0" viewBox="0 0 17.3 30">
                <path fill="#1d1d1b" d="M2.3 0L0 2.4l11 10.9 1.6 1.7-1.6 1.7L0 27.6 2.3 30l15-15z"/>
              </svg>
            </h4>
            <p>Finance upstart making himself heard among industry bigs</p>
            <hr />
          </a>
        </div>
      </div>
    </>
  )
};

export default PressPage;
